import { useMutation } from '@tanstack/react-query';

import { uploadImage } from 'api/requests/uploadImage/uploadImage';
import { UploadImageSuccess, UploadImageVariables } from 'api/requests/uploadImage/uploadImage.types';

export function useImageUpload() {
  return useMutation<UploadImageSuccess, Error, UploadImageVariables>({
    mutationKey: [queryKey],
    mutationFn: variables => {
      return uploadImage(variables);
    },
  });
}

export const queryKey = 'upload';
