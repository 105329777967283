import { AnyObject } from 'types/utils';

import {
  Prompt,
  Image,
  ImageSource,
  ImageRatio,
  ImageVariant,
} from 'components/pages/sparkPage/components/sparkPageCreate/SparkPageCreate.types';
import {
  ImageRatio as ImageRatioType,
  ImageSource as GQLImageSource,
  Month,
  PromptImageGenerated,
  PromptImagePrivateUpscaled,
  PromptImageUpscaled,
} from 'generated/graphql';
import { getWidthAndHeightFromImageUrl } from 'utilities/dom';
import { truthyBoolean } from 'utilities/js';
import { SparkTools } from 'components/pages/sparkPage/context/SparkPageContext.types';
import { ENABLE_SPARK_IMAGEMIX_ASPECT_RATIOS } from 'components/app/App.config';
import { AppRoute } from 'components/app/App.types';
import { toolsWithArbitraryRatios } from 'components/pages/sparkPage/config/sparkConfig';
import { SparkPageProductProps } from 'components/pages/sparkPage/components/sparkPageProduct/SparkPageProduct.types';
import { UploadedImageSource } from 'components/pages/sparkPage/context/CreateSparkPrompt.types';
import { extractFilenameFromUrl } from 'utilities/url';

import { getImageAspectRatioValue } from './getImageAspectRatio';

export function formatDate(date: Date) {
  const month = date.toLocaleString('en-us', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export function toPrompt(prompt: Prompt): Prompt {
  return {
    ...prompt,
    images: toPromptImageList(prompt.images ?? []),
    createTime: formatDate(new Date(`${prompt.createTime}`)),
  };
}

export function toPromptImageList(images: Array<Image | undefined>) {
  return images.filter(truthyBoolean).sort(sortProducts);
}

const sortProducts = (a: Image, b: Image) => {
  const order: GQLImageSource[] = [ImageSource.STABILITYAI, ImageSource.STABLEDIFFUSION_CF, ImageSource.DALLE];

  if (order.indexOf(a.source) < order.indexOf(b.source)) {
    return -1;
  }

  if (order.indexOf(a.source) > order.indexOf(b.source)) {
    return 1;
  }

  return `${a.id}`.localeCompare(`${b.id}`);
};

async function getImageAspectRatioFromUrl(url: string) {
  const { width, height } = await getWidthAndHeightFromImageUrl(url);

  return width / height;
}

const aspectRatioValueCache = new Map<string, number>();

function getAspectRatioCacheKey(prompt: string, tool: string, type: string) {
  return `${prompt}-${tool}-${type}`;
}

export async function getAspectRatioForPrompt({
  prompt,
  tool,
  type: _type,
}: {
  prompt: string;
  type?: ImageRatioType;
  tool: SparkTools;
}) {
  const type = _type ?? 'arbitrary';

  const cacheKey = getAspectRatioCacheKey(prompt, tool, type);

  if (!aspectRatioValueCache.has(getAspectRatioCacheKey(prompt, tool, type))) {
    let value: number;

    if (tool === 'cf-spark-imagemix') {
      if (ENABLE_SPARK_IMAGEMIX_ASPECT_RATIOS) {
        value = await getImageAspectRatioFromUrl(prompt).catch(() => {
          return 1;
        });
      } else {
        value = 1;
      }
    } else {
      value = getImageAspectRatioValue(type);
    }

    aspectRatioValueCache.set(getAspectRatioCacheKey(prompt, tool, type), value);
  }

  const value = aspectRatioValueCache.get(cacheKey) as number;

  if (toolsWithArbitraryRatios.has(tool)) {
    return { type: ImageRatio.ARBITRARY, value };
  }

  return { type, value };
}

export function getSparkImageDownloadProps({
  id,
  url,
  hasSparkSubscription,
}: {
  id: string;
  url?: string | null;
  hasSparkSubscription: boolean;
}) {
  let downloadableFilePath: string[] = [];

  if (url) {
    downloadableFilePath = url.replace('https://', '').replace('http://', '').split('/');
    downloadableFilePath.shift();
  }

  if (downloadableFilePath.length === 0 && url) {
    downloadableFilePath = [url];
  }

  return {
    href: hasSparkSubscription ? `/api/download-image/${id}` : '',
    download: hasSparkSubscription
      ? downloadableFilePath.join('_').replace('upscaled-private_', '').replace('upscaled_', '')
      : false,
  };
}

export function getSparkImageMixShareUrl(id: string | number) {
  return `${process.env.NEXT_PUBLIC_API_URL}${AppRoute.SPARK_IMAGEMIX_SHARE.replace('{id}', String(id))}`;
}

// typename is optional from the graphql scheme of `PromptImageUpscaled` etc.
// but here it is used as a union type so it's required
type WithTypename<T extends AnyObject> = T & { __typename: NonNullable<T['__typename']> };

export function hasPromptImage(
  image: SparkPageProductProps['image'],
): image is
  | WithTypename<PromptImageUpscaled>
  | WithTypename<PromptImageGenerated>
  | WithTypename<PromptImagePrivateUpscaled> {
  return (
    image.__typename === ImageVariant.UPSCALED ||
    image.__typename === ImageVariant.GENERATED ||
    image.__typename === ImageVariant.PRIVATE_UPSCALED
  );
}

const IMAGE_UPLOAD_URL_PREFIX_LIST = [
  'https://creativefabrica-images.s3.amazonaws.com/',
  'https://creativefabrica-images-staging.s3.amazonaws.com/',
  'https://images.creativefabrica.com/',
  'https://staging.creativefabrica.com/',
];

const validUrlOrUndefined = (url: string) => {
  try {
    return new URL(url);
  } catch (err) {
    return undefined;
  }
};

const extractDomainFromUrl = (url: URL, defaultValue: string): string =>
  url.hostname.replace('www.', '') ?? defaultValue;

export const getImagemixPromptValue = (
  url: string,
  i18n: {
    imageUploaded: string;
    imageWritten: string;
  },
): { prompt: string; imageUploaded: UploadedImageSource } => {
  const validUrl = validUrlOrUndefined(url);

  if (!validUrl) {
    return { prompt: url, imageUploaded: null };
  }

  return IMAGE_UPLOAD_URL_PREFIX_LIST.map(prefix => url.startsWith(prefix)).filter(Boolean).length > 0
    ? {
        prompt: `${i18n.imageUploaded} ${extractFilenameFromUrl(validUrl, '-')}`,
        imageUploaded: 'image',
      }
    : {
        prompt: `${i18n.imageWritten} ${extractDomainFromUrl(validUrl, '-')}`,
        imageUploaded: 'text',
      };
};

export const monthArray: Month[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
