import sparkPatterns from 'assets/spark-tools/CF-Spark-Patterns_logo.svg';
import sparkArt from 'assets/spark-tools/CF-Spark-Art_logo.svg';
import sparkTv from 'assets/spark-tools/CF-Spark-TV_logo.svg';
import sparkImageMix from 'assets/spark-tools/CF-Spark-ImageMix_logo.svg';
import sparkCrystalline from 'assets/spark-tools/CF-Spark-Crystalline_logo.svg';
import sparkSketch from 'assets/spark-tools/CF-Spark-Sketch_logo.svg';
import sparkColoringPage from 'assets/spark-tools/CF-Spark-Coloring-Pages_logo.svg';
import { Translations } from 'utilities/i18n';
import { SparkTools } from 'components/pages/sparkPage/context/SparkPageContext.types';

export const maxInputLength = {
  'cf-spark-patterns': 309,
};

const amountOfImagesGeneratedByTool: Partial<Record<SparkTools, number>> = {
  'cf-spark-imagemix': 12,
  'cf-spark-tv': 1,
  'cf-spark-crystalline': 4,
};

export function getAmountOfImagesGeneratedByTool(tool: SparkTools) {
  return amountOfImagesGeneratedByTool[tool] ?? 4;
}

export const toolsWithTransparencyGrid = new Set<SparkTools>(['cf-spark-crystalline', 'cf-spark-sketch'] as const);

export const toolsWithOnboardingFlow = new Set<SparkTools>([
  'cf-spark-art',
  'cf-spark-patterns',
  'cf-spark-tv',
  'cf-spark-crystalline',
  'cf-spark-sketch',
] as const);

export const toolsWithArbitraryRatios = new Set<SparkTools>(['cf-spark-imagemix'] as const);

export const imageMap: Record<SparkTools, { url: string; width: number; height: number }> = {
  'cf-spark-patterns': { url: sparkPatterns, width: 199, height: 32 },
  'cf-spark-art': { url: sparkArt, width: 199, height: 32 },
  'cf-spark-tv': { url: sparkTv, width: 199, height: 32 },
  'cf-spark-imagemix': { url: sparkImageMix, width: 199, height: 32 },
  'cf-spark-crystalline': { url: sparkCrystalline, width: 230, height: 32 },
  'cf-spark-sketch': { url: sparkSketch, width: 245, height: 32 },
  'cf-spark-coloring-page': { url: sparkColoringPage, width: 361, height: 32 },
};

export const imageAltMap: Record<SparkTools, Translations> = {
  'cf-spark-patterns': 'spark.title',
  'cf-spark-art': 'spark_page.patterns.title',
  'cf-spark-tv': 'spark.tools.tv.title',
  'cf-spark-imagemix': 'spark.tools.imagemix.title',
  'cf-spark-crystalline': 'spark.tools.crystalline.title',
  'cf-spark-sketch': 'spark.tools.sketch.title',
  'cf-spark-coloring-page': 'spark.tools.coloring_page.title',
};

export const shareableImageTools: Set<SparkTools> = new Set(['cf-spark-imagemix'] as const);
