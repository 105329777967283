import { ImageRatios } from 'components/pages/sparkPage/components/sparkImageRatioSelect/SparkImageRatioSelect.types';
import { ImageRatio } from 'generated/graphql';
import { SparkTools } from 'components/pages/sparkPage/context/SparkPageContext.types';

const aspectRatioValueMap: Partial<Record<ImageRatio, number>> = {
  oneToOne: 1,
  twoToThree: 2 / 3,
  threeToTwo: 3 / 2,
  nineToSixteen: 9 / 16,
  sixteenToNine: 16 / 9,
  fourToFive: 4 / 5,
  onePointNinetyOneToOne: 1.91,
  landscape: Math.SQRT2,
  portrait: 1 / Math.SQRT2,
  square: 1,
};

export function getImageAspectRatioValue(imageRatio: ImageRatio) {
  if (!aspectRatioValueMap[imageRatio] && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(`No aspect ratio value found for ${imageRatio}`);
  }

  return aspectRatioValueMap[imageRatio] ?? 1;
}

const labelMap: Partial<Record<ImageRatio, string>> = {
  oneToOne: ImageRatios.ONE_TO_ONE,
  twoToThree: ImageRatios.VERTICAL_RECTANGLE,
  threeToTwo: ImageRatios.HORIZONTAL_RECTANGLE,
  nineToSixteen: ImageRatios.TALL,
  sixteenToNine: ImageRatios.WIDESCREEN,
  fourToFive: ImageRatios.PORTRAIT,
  onePointNinetyOneToOne: ImageRatios.LANDSCAPE,
  landscape: ImageRatios.A4_LANDSCAPE,
  portrait: ImageRatios.A4_PORTRAIT,
  // square is "square" as a value, although as a label it's still 1:1
  square: ImageRatios.ONE_TO_ONE,
};

export function getImageAspectRatioLabel(imageRatio: ImageRatio) {
  if (!labelMap[imageRatio] && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(`No aspect ratio label found for ${imageRatio}`);
  }

  return labelMap[imageRatio] ?? ImageRatios.ONE_TO_ONE;
}

type AspectRatioMap = {
  [key: string]: ImageRatio;
};

const aspectRatioMap: AspectRatioMap = {
  [ImageRatios.ONE_TO_ONE]: 'oneToOne',
  [ImageRatios.HORIZONTAL_RECTANGLE]: 'threeToTwo',
  [ImageRatios.VERTICAL_RECTANGLE]: 'twoToThree',
  [ImageRatios.TALL]: 'nineToSixteen',
  [ImageRatios.WIDESCREEN]: 'sixteenToNine',
  [ImageRatios.PORTRAIT]: 'fourToFive',
  [ImageRatios.LANDSCAPE]: 'onePointNinetyOneToOne',
  [ImageRatios.A4_LANDSCAPE]: 'landscape',
  [ImageRatios.A4_PORTRAIT]: 'portrait',
  [ImageRatios.SQUARE]: 'square',
};

export function getImageAspectRatioFromImageRatios(imageRatio: string) {
  if (!aspectRatioMap[imageRatio] && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(`No aspect ratio value found for ${imageRatio}`);
  }

  return aspectRatioMap[imageRatio];
}

const initialImageRatioMap: Partial<Record<SparkTools, ImageRatios>> = {
  'cf-spark-coloring-page': ImageRatios.SQUARE,
};

export function getInitialImageRatioMap(tool: SparkTools) {
  return initialImageRatioMap[tool] ?? ImageRatios.ONE_TO_ONE;
}
