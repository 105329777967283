import { AppRoute } from 'components/app/App.types';

export function getReviewAppRouteWithId(id: string | number) {
  return `${AppRoute.REVIEWS}${id}/`;
}

export function getProductAppRouteWithId(id: string | number) {
  return `${AppRoute.PRODUCT}${id}/`;
}

export function isValidHttpUrl(string: string) {
  try {
    const url = new URL(string);

    return url.protocol.startsWith('http');
  } catch {}

  return false;
}

export function extractFileExtensionFromFileName(input: string) {
  if (!input.includes('.')) {
    return null;
  }

  return input.split('.').pop() as string;
}

export function extractFilenameFromUrl(url: URL, defaultValue: string): string {
  const fileName = url.pathname.split('/').reverse().shift() ?? '';

  //remove singedUrl added string
  //it follows this pattern: [key]_[hash]_[filename]
  const splitFileName = fileName.split('_');

  //the filename does not have _ in the name
  if (splitFileName.length <= 3) {
    return decodeURIComponent(splitFileName.reverse().shift() ?? defaultValue);
  }

  //the filename does have _ in the name so we need to it back
  let returnedFileName = '';
  for (let i = 2; i < splitFileName.length; i++) {
    returnedFileName = returnedFileName + (i === 2 ? '' : '_') + splitFileName[i];
  }

  return decodeURIComponent(returnedFileName);
}
