import { fetcher } from 'api/fetcher';

import { UploadImageVariables, UploadImageSuccess } from './uploadImage.types';

async function readFile(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result);
    });
    reader.readAsArrayBuffer(file);
  });
}

export async function uploadImage(variables: UploadImageVariables): Promise<UploadImageSuccess> {
  const fileData = await readFile(variables.image);
  let requestFailed = false;

  const fileType = variables.image.type;

  const headers: Headers = variables.headers
    ? variables.headers
    : new Headers({
        'x-amz-acl': 'public-read',
      });

  if (fileType === 'image/svg+xml') {
    headers.set('Content-Type', 'image/svg+xml');
  }

  try {
    await fetcher({
      url: variables.signedUrl,
      options: {
        method: 'PUT',
        body: fileData,
        headers,
      },
      type: 'external',
      onError: err => {
        requestFailed = true;
      },
    });

    if (requestFailed) {
      throw new Error('Something went wrong');
    }

    return { url: variables.signedUrl.split('?')[0] };
  } catch (err) {
    throw new Error('Something went wrong');
  }
}
