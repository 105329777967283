export enum ImageRatios {
  SQUARE = 'square',
  ONE_TO_ONE = '1:1',
  VERTICAL_RECTANGLE = '2:3',
  HORIZONTAL_RECTANGLE = '3:2',
  TALL = '9:16',
  WIDESCREEN = '16:9',
  PORTRAIT = '4:5',
  LANDSCAPE = '1.91:1',
  A4_PORTRAIT = '1.4142:1',
  A4_LANDSCAPE = '1:1.4142',
}
