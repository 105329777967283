import { GetValueDeep } from 'types/utils';

import { Translations } from 'utilities/i18n';
import { SparkTools } from 'components/pages/sparkPage/context/SparkPageContext.types';
import { AspectRatio, CreationType, SparkPromptsQuery } from 'generated/graphql';

export type Prompt = GetValueDeep<SparkPromptsQuery, 'me.user.spark.prompts.items.0'>;
export type Image = GetValueDeep<Prompt, 'images.0'>;

//* INFO: This is created from scratch
//TODO: Update the below types after BE is ready
export enum VideoRatio {
  ONE_TO_ONE = 'oneToOne',
  TWO_TO_THREE = 'twoToThree',
  THREE_TO_TWO = 'threeToTwo',
  NINE_TO_SIXTEEN = 'nineToSixteen',
  SIXTEEN_TO_NINE = 'sixteenToNine',
  FOUR_TO_FIVE = 'fourToFive',
  ONE_POINT_NINETY_ONE_TO_ONE = 'onePointNinetyOneToOne',
}

export enum VideoSource {
  STABILITYAI = 'stabilityAi',
  STABLEDIFFUSION_CF = 'stableDiffusionCf',
  DALLE = 'dalle',
}

export enum VideoVariant {
  GENERATING = 'PromptVideoGenerating',
  GENERATED = 'PromptVideoGenerated',
  UPSCALING = 'PromptVideoUpscaling',
  UPSCALED = 'PromptVideoUpscaled',
  PRIVATE_UPSCALING = 'PromptVideoPrivateUpscaling',
  PRIVATE_UPSCALED = 'PromptVideoPrivateUpscaled',
}

export type Video =
  | {
      __typename: VideoVariant.GENERATED;
      id: string;
      source: VideoSource;
      video: { __typename?: 'Video'; url: string };
    }
  | { __typename: VideoVariant.GENERATING; id: string; source: VideoSource }
  | {
      __typename: VideoVariant.PRIVATE_UPSCALED;
      id: string;
      source: VideoSource;
      video: { __typename?: 'Video'; url: string; upscaledFile: string };
    }
  | { __typename: VideoVariant.PRIVATE_UPSCALING; id: string; source: VideoSource }
  | {
      __typename: VideoVariant.UPSCALED;
      id: string;
      source: VideoSource;
      video: { __typename?: 'Video'; url: string };
      product:
        | { __typename?: 'Bundle'; url: string; status: ProductStatus; visibility: ProductVisibility }
        | { __typename?: 'ClassProduct'; url: string; status: ProductStatus; visibility: ProductVisibility }
        | {
            __typename?: 'CommunityContentProduct';
            url: string;
            status: ProductStatus;
            visibility: ProductVisibility;
          }
        | { __typename?: 'Product'; url: string; status: ProductStatus; visibility: ProductVisibility };
    }
  | { __typename: VideoVariant.UPSCALING; id: string; source: VideoSource };

export type VideoPrompt = {
  id: string;
  prompt: string;
  aspectRatio: AspectRatio;
  createTime?: string;
  creationType?: CreationType | null;
  video: Video;
};

export type HeadingMap = {
  [key in SparkTools]: Translations;
};

export enum ImageRatio {
  ONE_TO_ONE = 'oneToOne',
  TWO_TO_THREE = 'twoToThree',
  THREE_TO_TWO = 'threeToTwo',
  NINE_TO_SIXTEEN = 'nineToSixteen',
  SIXTEEN_TO_NINE = 'sixteenToNine',
  FOUR_TO_FIVE = 'fourToFive',
  ONE_POINT_NINETY_ONE_TO_ONE = 'onePointNinetyOneToOne',
  ARBITRARY = 'arbitrary',
  SQUARE = 'square',
}

export enum ImageSource {
  STABILITYAI = 'stabilityAi',
  STABLEDIFFUSION_CF = 'stableDiffusionCf',
  DALLE = 'dalle',
}

export enum ProductStatus {
  DRAFT = 'draft',
  FUTURE = 'future',
  PENDING = 'pending',
  PUBLISH = 'publish',
  REMOVED = 'removed',
  TRASH = 'trash',
  UNSPECIFIED = 'unspecified',
}

export enum ProductVisibility {
  HIDDEN = 'hidden',
  VISIBLE = 'visible',
}

export enum ImageVariant {
  GENERATING = 'PromptImageGenerating',
  GENERATED = 'PromptImageGenerated',
  FAILED_GENERATING = 'PromptImageFailedGenerating',
  PRIVATE_UPSCALING = 'PromptImagePrivateUpscaling',
  UPSCALING = 'PromptImageUpscaling',
  UPSCALED = 'PromptImageUpscaled',
  PRIVATE_UPSCALED = 'PromptImagePrivateUpscaled',
}
